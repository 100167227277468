import '../css/login.css';
import ApiService from "../service/api-service";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";

function Login() {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams.has('error')) {
            toast.error(searchParams.get('error'), {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored", autoClose: true
            })
            searchParams.delete('error');
            setSearchParams(searchParams);
        }
        if (searchParams.has('access_token')) {
            localStorage.setItem('rabbithole-user', searchParams.get('access_token'));
            searchParams.delete('access_token');
            setSearchParams(searchParams);
            navigate('/');
        }
    }, [searchParams])
    const getAuthUrl = () => {
        ApiService.get('/login/auth-url').then(resp => {
            if (resp.status === 200) {
                window.location.href = resp.data.url;
            }
        }).catch(e => {
            toast.error(e.message, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored", autoClose: true
            })
        })
    }

    return (
        <div className='login'>
            <div className='content-main'>
                <h3>Welcome To Rabbithole.ai</h3>
                <div className={'login-details'}>
                    {/*<button onClick={() => onSuccess({*/}
                    {/*    "merkle_root": "0x1f38b57f3bdf96f05ea62fa68814871bf0ca8ce4dbe073d8497d5a6b0a53e5e0",*/}
                    {/*    "nullifier_hash": "0x0339861e70a9bdb6b01a88c7534a3332db915d3d06511b79a5724221a6958fbe",*/}
                    {/*    "proof": "0x063942fd7ea1616f17787d2e3374c1826ebcd2d41d2394...",*/}
                    {/*    "credential_type": "orb"*/}
                    {/*})}>Direct</button>*/}
                    <button onClick={() => getAuthUrl()}>Sign In With Worldcoin</button>
                </div>
            </div>
        </div>
    )
}

export default Login;
