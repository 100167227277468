import axios from "axios";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 120 * 1000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': ' application/json'
    }
});

axiosInstance.interceptors.response.use((response) => {
    return response;
}, error => {
    return Promise.reject(error);
});

class ApiService {
    static post(url, payload, headers, options = {}) {
        return axiosInstance.post(url, payload, {
            headers: {
                ...headers
            },
            ...options
        });
    }
    static get(url, payload, headers, options = {}) {
        return axiosInstance.get(url, {
            headers: {
                ...headers
            },
            ...options,
            params: payload
        });
    }
}

export default ApiService

