import '../css/ui-3.css';
import TreeView from "react-accessible-treeview";
import {IoMdArrowDropright} from "react-icons/io";
import cx from "classnames";
import {useCallback, useEffect, useRef, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoopIcon from '@mui/icons-material/Loop';
import ApiService from "../service/api-service";
import {Alert, Collapse} from "@mui/material";
import moment from "moment";
import {LuPanelLeftClose, LuPanelRightClose} from "react-icons/lu";
import {FiMessageSquare} from "react-icons/fi";
import {HiPlus} from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import {MdLogout} from "react-icons/md";

let tc = [{name: '', id: 0, children: [], parent: null, metadata: {}}];
let currentOnGoingStreams = [];
let allResponse = [];
let lastId = 1;
let breadCrumbs = [];
let colors = ['Black', 'Purple', 'Red', 'Yellow', 'Orange'];
let topics = [];
let threadId = '';
let timer = null;

function UIDashboard() {
    const [bio, setBio] = useState('');
    const [value, setValue] = useState('');
    const submitButton = useRef(null);
    const [treeData, setTreeData] = useState([
        {name: '', id: 0, children: [], parent: null, metadata: {}}
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [expandedIds, setExpandedIds] = useState([]);
    const [nodesAlreadyLoaded, setNodesAlreadyLoaded] = useState([]);
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [tokenText, setTokenText] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [isShowSideBar, setIsShowSideBar] = useState(true);
    const [allResults, setAllResults] = useState([]);

    const scrollRef = useRef({});
    const navigate = useNavigate();

    const getAllResponse = useCallback(() => {
        ApiService.get('/get-topic', null, {'Authorization': `Bearer ${localStorage.getItem('rabbithole-user')}`})
            .then((response) => {
                if (response) {
                    if (response.data && response.data.length) {
                        const sortedDates = response.data?.map(obj => {
                            return {...obj, date: new Date(obj.createdAt)}
                        })
                            .sort((a, b) => b.date - a.date)
                        const groupedData = sortedDates.reduce((acc, obj) => {
                            const {createdAt, ...rest} = obj;
                            let date = moment(createdAt).format('DD/MM/YYYY')
                            if (date === moment().format('DD/MM/YYYY')) {
                                date = 'Today';
                            } else if (date === moment().subtract(1, 'days').format('DD/MM/YYYY')) {
                                date = 'Yesterday';
                            } else if (date < moment().subtract(2, 'days').format('DD/MM/YYYY') && date > moment().subtract(6, 'days').format('DD/MM/YYYY')) {
                                date = 'Previous 7 Days'
                            } else if (moment(createdAt).format('MM') !== moment().format('MM')) {
                                date = moment(createdAt).format('MMMM')
                            } else if (moment(createdAt).format('YYYY') !== moment().format('YYYY')) {
                                date = 'Year ' + moment(createdAt).format('YYYY')
                            } else {
                                date = 'Previous 30 Days';
                            }
                            if (!acc[date]) {
                                acc[date] = [];
                            }
                            acc[date].push(rest);
                            return acc;
                        }, {});
                        setAllResults(groupedData);
                    }
                }
            }).catch((error) => {
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored", autoClose: true
            })
        });
    }, [])


    useEffect(() => {
        getAllResponse()
    }, [getAllResponse])

    const updateTreeContent = (treeContent) => {
        tc = [...treeContent];
        setTreeData([...treeContent])
    }

    const updateTreeData = useCallback(
        (list, id, children, replaceChild = true) => {
            let data = list;
            if (replaceChild) {
                data = list.map((node) => {
                    if (node.id === id) {
                        node.children = children.map((el) => {
                            return el.id;
                        });
                    }
                    return node;
                });
            }
            return data.concat(children);
        },
        []
    );

    const handleBioInputChange = (e, isSet) => {
        setBio(e.target.value); // directly set the bio state
        if (isSet) {
            threadId = null;
            topics = [];
            timer = null;
            setIsExpanded(false)
            currentOnGoingStreams.forEach(t => t.res.cancel());
            currentOnGoingStreams = [];
            setValue(e.target.value);
            setExpandedIds([]);
            allResponse = [];
            breadCrumbs = [];
            setBreadcrumb([]);
            lastId = 1;
            updateTreeContent([{name: '', id: 0, children: [], parent: null, metadata: {}}]);
        }
    };

    const callAPI = async (body) => {
        return await fetch(process.env.REACT_APP_API_URL + '/chat-3', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('rabbithole-user')}`
            },
            body: JSON.stringify(body)
        })
    }

    const addChildForStreaming = (parent) => {
        let childId = getId();
        let updatedTree = updateTreeData(tc, parent, [
            {
                name: {name: '', description: '', showLoading: true},
                children: [],
                id: childId,
                parent,
                isBranch: false,
                metadata: {}
            }
        ]);
        updateTreeContent(updatedTree);
        return childId;
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true);
            topics.push(bio);
            let res = await callAPI({bio});
            if (res.ok) {
                setIsExpanded(true);
                const reader = res.body.getReader();
                currentOnGoingStreams.push({id: 0, res: reader, oRes: res});
                let {branchesId, infoId} = pushInitialBranches(0);
                let infoTree = tc.find(t => t.id === infoId);
                setExpandedIds(prev => [...prev, branchesId]);
                fetchOthers('Info', infoTree);
                let childIdToShowStreaming = addChildForStreaming(branchesId);
                processStream(reader, branchesId, childIdToShowStreaming);
                updateTreeContent(tc);
                assignChildToTree();
                updateBreadCrumbs({name: {name: bio}, id: 1, children: []});
            }
        } catch (e) {
            toast.warning(e.message, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored", autoClose: false
            })
            console.log('=====e====', e);
        }
    };

    const processStream = async (reader, parent, childId) => {
        while (true) {
            try {
                const {done, value: decodeValue} = await reader.read();
                if (done) {
                    processDone(parent, childId);
                    break;
                }
                // Set streaming value
                let streamData = new TextDecoder('utf-8').decode(decodeValue);
                let con = streamData.replace(/\{|\}|\"|\,|branches|#|name|description|url|\[|\]|:/g, '');
                setIsLoading(false);
                updateTreeContent(tc.map(t => {
                    if (t.id === childId) {
                        t.name = {name: '', description: (t.name.description || '') + con, showLoading: true};
                        t.metadata = {
                            ...(t.metadata || {}),
                            originalContent: (t.metadata.originalContent || '') + new TextDecoder('utf-8').decode(decodeValue)
                        };
                    }
                    return t;
                }))
            } catch (error) {
                toast.warning(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored", autoClose: false
                })
                console.log('e', error)
                break;
            }

        }
    }

    function getId() {
        lastId = lastId + 1;
        return lastId;
    }

    const processDone = async (parent, childId) => {
        try {
            let count = 0
            currentOnGoingStreams = currentOnGoingStreams.filter(d => d.id !== parent);
            let child = tc.find(t => t.id === childId);
            let parentTree = tc.find(t => t.id === parent);
            if (!child) {
                return;
            }
            let data = child.metadata.originalContent;
            let contentObj;
            let name = parentTree.name.name;
            if (parentTree.name.hasOwnProperty('moreLink')) {
                name = 'Paragraph'
            }
            if (parentTree.name.hasOwnProperty('paraLink')) {
                name = 'Essay'
            }
            if (parentTree.name.name === '') {
                name = 'Info'
            }
            if (!['Info', 'Paragraph', 'Essay'].includes(name)) {
                try {
                    contentObj = JSON.parse(data);
                } catch (error) {
                    toast.warning('Error parsing JSON:', {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored", autoClose: false
                    })
                    console.error('Error parsing JSON:', error);
                    return;
                }
            } else {
                contentObj = data;
            }
            if (!['Info', 'Paragraph', 'Essay'].includes(name)) {
                if (name === 'YouTube') {
                    const channels = Object.values(contentObj.channels)
                    channels.forEach(i => {
                        tc.push({
                            name: {name: i.name, description: i.description, url: i.url},
                            id: getId(),
                            parent: parent,
                            isBranch: false,
                            children: [],
                            metadata: {}
                        })
                    });
                    tc = tc.filter(t => t.id !== childId);
                    updateTreeContent(tc);
                    assignChildToTree();
                } else {
                    allResponse.push(data);
                    updateTreeContent(tc.map(t => {
                        if (t.id === parent) {
                            t.metadata.originalContent = child.metadata.originalContent;
                        }
                        return t;
                    }))
                    tc = tc.filter(t => t.id !== childId);
                    contentObj.forEach(item => {
                        let nodeId = getId();
                        if (count === 5) {
                            count = 0;
                        }
                        tc.push({
                            name: {name: item.name},
                            id: nodeId,
                            parent: parent,
                            isBranch: true,
                            children: [],
                            metadata: {isExpanded: false, class: colors[count]}
                        });
                        count++
                        pushInitialBranches(nodeId);
                    })
                    if (parentTree.name.name === 'Branches') {
                        if (count === 5) {
                            count = 0;
                        }
                        tc.push({
                            name: {name: '+ More Branches'},
                            id: getId(),
                            parent: parent,
                            isBranch: true,
                            children: [],
                            metadata: {class: colors[count]}
                        });
                    }
                    updateTreeContent(tc);
                    assignChildToTree();
                }
            } else {
                updateTreeContent(tc.map(t => {
                    if (t.id === parent) {
                        t.metadata.originalContent = child.metadata.originalContent;
                    }
                    return t;
                }))
                if (parent !== childId) {
                    tc = tc.filter(t => t.id !== childId);
                    let newName = {
                        name: '',
                        description: data
                    };
                    if (name === 'Paragraph') {
                        newName = {name: data, description: '', paraLink: true}
                    }

                    tc.push({
                        name: newName,
                        id: getId(),
                        parent: parent,
                        isBranch: name === 'Paragraph',
                        children: [],
                        metadata: {}
                    })
                } else {
                    let childIndex = tc.findIndex(t => t.id === childId);
                    tc[childIndex] = {
                        ...tc[childIndex],
                        name: {name: data, description: '', moreLink: true},
                        isBranch: true
                    }
                }
                updateTreeContent(tc);
                assignChildToTree()
            }
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(async () => {
                await addResponseToThread();
            }, 1500);
        } catch (e) {
            console.log(e);
            toast.warning(e.message, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored", autoClose: false
            })
        }
    }

    const addResponseToThread = async () => {
        const data = JSON.stringify(tc);
        let body = {
            content: data,
            topics,
            threadId: threadId ? threadId : null
        }
        const response = await ApiService.post('/add-thread', body, {'Authorization': `Bearer ${localStorage.getItem('rabbithole-user')}`})
        if (response.status === 200) {
            threadId = response.data._id;
            getAllResponse();
        }
    }

    function assignChildToTree() {
        let finalTc = [];
        tc.forEach(t => {
            let child = [];
            tc.filter(child => child.parent === t.id).forEach(x => {
                child.push(x.id);
            })
            // child.sort().reverse();
            // if (sortChilds) {
            // } else {
            //     child.sort();
            // }

            //  && (child && child.length > 0)
            finalTc.push({
                ...t,
                children: child
            });
        })
        updateTreeContent(finalTc);
    }

    function pushInitialBranches(parent) {
        const infoId = getId();
        const branchesId = getId();
        const youtubeId = getId();
        tc.push({
            name: {name: ''},
            id: infoId,
            parent: parent,
            isBranch: false,
            children: [],
            metadata: {infoBranch: true}
        })
        tc.push({
            name: {name: 'Branches'},
            id: branchesId,
            parent: parent,
            isBranch: true,
            children: [],
            metadata: {}
        })
        tc.push({
            name: {name: 'YouTube'},
            id: youtubeId,
            parent: parent,
            isBranch: true,
            children: [],
            metadata: {}
        })
        return {branchesId, infoId, youtubeId};
    }

    const fetchOthers = async (bioInput, element) => {
        try {
            if (tc.length === 1) {
                tc = [...treeData];
            }
            let body;
            let currentToolsElement = tc.findIndex(t => t.id === element.id);
            let isMoreBranches = false;
            if (element.name.name === '+ More Branches') {
                isMoreBranches = true;
                tc[currentToolsElement].name.name = '';
            }
            updateTreeContent(tc);
            currentToolsElement = tc[currentToolsElement];
            let inputValue = [];
            while (currentToolsElement) {
                currentToolsElement.name.name && inputValue.unshift(currentToolsElement.name.name);
                currentToolsElement = tc.find(t => t.id === currentToolsElement.parent);
            }
            inputValue.unshift(value);

            inputValue = inputValue.filter(t => t !== 'Branches');

            if (bioInput === 'More Branches') {
                body = {
                    bio: {
                        input: inputValue.join(' > '),
                        assistance: allResponse,
                        moreInput: 'List additional branches'
                    }, more: ''
                }
                topics.push(body.bio.input + ' > ' + body.bio.moreInput);
            } else {
                if (bioInput === 'Essay' || bioInput === 'Paragraph') {
                    let currentToolsElement = tc.find(t => t.id === element.id);
                    body = {bio: currentToolsElement.name.name, more: bioInput}
                } else {
                    body = {bio: inputValue.join(' > '), more: bioInput}
                }
                topics.push(body.bio + (body.more !== 'YouTube' && ' > ' + body.more));
            }
            /* ********************************************************************* */
            let res = await callAPI(body);
            if (res.ok) {
                const reader = res.body.getReader();
                currentOnGoingStreams.push({id: element.id, res: reader, oRes: res});
                if (bioInput !== 'Info') {
                    if (isMoreBranches) {
                        processStream(reader, element.parent, element.id);
                    } else {
                        let childIdToShowStreaming = addChildForStreaming(element.id);
                        processStream(reader, element.id, childIdToShowStreaming);
                    }
                } else {
                    processStream(reader, element.id, element.id);
                }
            }
        } catch (e) {
            console.log('--------E---------', e)
            toast.warning(e.message, {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
                autoClose: false
            })
        }
    }

    function updateBreadCrumbs(element, expanded = false) {
        if (element.name.name === 'Branches') {
            if (!expanded) {
                removeAllSubBranch(element.children);
            } else {
                addAllSubBranches(element.children);
            }
            setBreadcrumb([...breadCrumbs]);
            return;
        }
        let itemExists = breadCrumbs.find(t => t.id === element.id);
        if (!itemExists) {
            let checkIfParentIsBranch = tc.find(t => t.id === element.parent);
            if (checkIfParentIsBranch && checkIfParentIsBranch.name.name !== 'Branches') {
                return;
            }
            breadCrumbs.push(element);
            let findBranches = tc.find(t => element.children.includes(t.id) && t.name.name === 'Branches');
            if (!findBranches) {
                return;
            }
            addAllSubBranches(findBranches.children);
            setBreadcrumb([...breadCrumbs]);
        } else {
            removeElementFromBreadcrumbs(element.id);
            removeAllSubBranch(element.children);
            setBreadcrumb([...breadCrumbs]);
        }
    }

    function removeAllSubBranch(children) {
        breadCrumbs = breadCrumbs.filter(t => !children.includes(t.id));
        children.forEach(t => {
            let item = tc.find(d => d.id === t);
            if (item && item.isBranch && item.children.length > 0) {
                removeAllSubBranch(item.children)
            }
        })
    }

    function addAllSubBranches(children) {
        children.forEach(t => {
            let item = tc.find(d => d.id === t);
            if (item && item.metadata && item.metadata.isExpanded) {
                if (item.name.name !== 'Branches') {
                    breadCrumbs.push(item);
                }
                if (item.isBranch && item.children.length > 0) {
                    addAllSubBranches(item.children);
                }
            }
        })
    }

    const removeElementFromBreadcrumbs = (itemId) => {
        const existsIndex = breadCrumbs.findIndex(t => t.id === itemId);
        if (existsIndex >= 0) {
            breadCrumbs.splice(existsIndex, 1);
        }
    };

    const wrappedOnLoadData = async (props) => {
        branchExpandLogic(props.element);
        const nodeHasNoChildData = props.element.children.length === 0;
        const nodeHasAlreadyBeenLoaded = nodesAlreadyLoaded.find(e => e.id === props.element.id);
        if (!nodeHasAlreadyBeenLoaded && nodeHasNoChildData) {
            setNodesAlreadyLoaded([...nodesAlreadyLoaded, props.element]);
            if (props.element.name.moreLink) {
                fetchOthers('Paragraph', props.element)
            } else if (props.element.name.paraLink) {
                fetchOthers('Essay', props.element)
            } else {
                let name = props.element.name.name;
                let element = props.element;
                if (element.name.name === 'Branches' || element.name.name === '+ More Branches') {
                    name = 'More Branches'
                }
                fetchOthers(name, element)
            }
        } else {
            if (props.element.name.name !== 'Branches') {
                let children = tc.filter(t => props.element.children.includes(t.id));
                let infoChild = children.find(t => t.metadata && t.metadata.infoBranch);
                let branchExpanded = tc.find(t => props.element.id === t.id && t.metadata);
                tc = tc.map(t => {
                    if (branchExpanded.id === t.id) {
                        t.metadata.isExpanded = props.isExpanded
                    }
                    return t;
                })
                updateTreeContent(tc);
                if (infoChild) {
                    let infoIndex = tc.findIndex(t => t.id === infoChild.id);
                    delete tc[infoIndex].metadata.infoBranch;
                    updateTreeContent(tc);
                    fetchOthers('Info', infoChild);
                }
            }
        }
    };

    function branchExpandLogic(element) {
        let children = tc.filter(t => element.children.includes(t.id));
        let branchChild = children.find(t => t.name.name === 'Branches');
        if (branchChild) {
            tc = tc.map(t => {
                if (branchChild.id === t.id) {
                    t.metadata.isExpanded = !t.metadata.isExpanded
                }
                return t;
            })
            updateTreeContent(tc);
            if (scrollRef.current[branchChild.id]) {
                let grandParentNode = scrollRef.current[branchChild.id].parentNode;
                let lastChild = grandParentNode.lastChild;
                if (lastChild.classList.contains('tree-node-group--expanded')) {
                    return;
                }
                setTimeout(() => {
                    scrollRef.current[branchChild.id].firstChild.click();
                }, 300);
            }
        }
    }

    const navigateToBranch = (props) => {
        if (scrollRef.current) {
            const element = scrollRef.current[props.id];
            let offsetPosition = 0
            if (props.id !== 1) {
                const headerElement = document.getElementsByClassName('breadcrumb');
                const headerOffset = headerElement[0].clientHeight + 10;
                const elementPosition = element.getBoundingClientRect().top;
                offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            }
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    const keydownHandler = (e) => {
        if (e.key === 'x' && (e.ctrlKey || e.metaKey)) {
            ApiService.get('get-token', null, {'Authorization': `Bearer ${localStorage.getItem('rabbithole-user')}`})
                .then((response) => {
                    if (response.status === 200) {
                        setTokenText(response.data)
                        setShowAlert(true);
                    }
                }).catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored", autoClose: true
                })
            });
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => {
            document.removeEventListener('keydown', keydownHandler);
        }
    }, []);

    const handleNewChat = (isNew) => {
        if (isNew) {
            threadId = null;
            topics = [];
            timer = null;
        }
        setIsExpanded(false)
        setValue('');
        setBio('');
        currentOnGoingStreams.forEach(t => t.res.cancel());
        currentOnGoingStreams = [];
        setExpandedIds([]);
        allResponse = [];
        breadCrumbs = [];
        setBreadcrumb([]);
        lastId = 1;
        updateTreeContent([{name: '', id: 0, children: [], parent: null, metadata: {}}]);
    }

    const handleChange = (item) => {
        handleNewChat(false);
        let treeData = JSON.parse(item.content);
        ApiService.get('/getTopicResponse', {topic: item.topics[0]}, {'Authorization': `Bearer ${localStorage.getItem('rabbithole-user')}`})
            .then(resp => {
                if (resp.status === 200) {
                    allResponse.push(resp.data.response);
                }
            }).catch(error => {
                console.log('=====error=====', error)
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored", autoClose: true
                })
            })
        treeData = treeData.map(t => {
            if (t.metadata && t.metadata.isExpanded) {
                t.metadata.isExpanded = false
            }
            return t;
        })
        setValue(item.topics && item.topics[0] ? item.topics[0] : '');
        setIsExpanded(true);
        threadId = item._id;
        topics = item.topics;
        timer = null;
        lastId = treeData[treeData.length - 1].id + 1;
        updateTreeContent(treeData)
        setExpandedIds(prev => [...prev, 3]);
    }

    const logout = () => {
        localStorage.removeItem('rabbithole-user');
        navigate("/signin");
    }

    return (
        <main className="main-body">
            <div className={`sidebar ${!isShowSideBar && 'hide-sidebar'}`}>
                <div className={isShowSideBar ? 'sidebar-chat' : 'hide-chat'}>
                    {isShowSideBar && <div className='new-chat' onClick={() => handleNewChat(true)}>
                        <HiPlus/>
                        <span>New Chat</span>
                    </div>}
                    <div className={isShowSideBar ? "show-icon" : 'close-icon'}
                         onClick={() => setIsShowSideBar(!isShowSideBar)}>
                        {isShowSideBar ? <LuPanelLeftClose/> : <LuPanelRightClose/>}
                    </div>
                </div>
                <div className='sidebar-list'>
                    <div className='sidebar-scroll'>
                        {allResults && Object.entries(allResults).map(([key, value]) => (
                            <div key={key}>
                                <div className="title">
                                    {key}
                                </div>
                                <ul className='search-list'>
                                    {value.map(item => (
                                        <li key={item._id}>
                                            <div onClick={() => handleChange(item)}>
                                                <FiMessageSquare/>
                                                <div
                                                    className={'search-name'}>{item.topics && item.topics[0] ? item.topics[0] : ''}
                                                    <div className='list-shadow'/>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='footer'>
                    <div className='user-detail' onClick={logout}>
                        <MdLogout/>
                        {isShowSideBar && <span>Log Out</span>}
                    </div>
                </div>
            </div>
            <div className="container-main">
                <Collapse in={showAlert}>
                    <Alert variant="filled" severity="success" onClose={() => setShowAlert(false)}>
                        <div style={{display: 'flex'}}>
                            <div style={{marginRight: '20px'}}>
                                <h5>Your Usage</h5>
                                <div>
                                    Per Day: {tokenText['day']?.token || 0} Tokens, ${tokenText['day']?.cost || 0}
                                    <br/>
                                    Per Hour: {tokenText['hour']?.token || 0} Tokens,
                                    ${tokenText['hour']?.cost || 0}<br/>
                                    Per Minute: {tokenText['minute']?.token || 0} Tokens,
                                    ${tokenText['minute']?.cost || 0}
                                </div>
                            </div>
                            <div>
                                <h5>All User Usage</h5>
                                <div>
                                    Per Day: {tokenText['aDay']?.token || 0} Tokens,
                                    ${tokenText['aDay']?.cost || 0}<br/>
                                    Per Hour: {tokenText['aHour']?.token || 0} Tokens,
                                    ${tokenText['aHour']?.cost || 0}<br/>
                                    Per Minute: {tokenText['aMinute']?.token || 0} Tokens,
                                    ${tokenText['aMinute']?.cost || 0}
                                </div>
                            </div>
                        </div>
                    </Alert>
                </Collapse>
                <ToastContainer/>
                <form className='search-header-form' onSubmit={handleSubmit}>
                    <div className="content-header">
                        <div style={{fontSize: '30px'}}>
                            Rabbithole.ai
                            <p style={{fontSize: '15px'}}>enter a topic to rabbithole</p>
                        </div>
                    </div>
                    {breadcrumb && breadcrumb.length > 1 && <ul className={'breadcrumb'}>
                        {breadcrumb && breadcrumb.map(item => (
                            <li key={item.id} className={'breadcrumb-item'} onClick={() => navigateToBranch(item)}>
                                {item.name.name}
                            </li>
                        ))}
                    </ul>}
                    <div className="content-input">
                        <ArrowIcon isOpen={isExpanded} className={`${treeData.length > 1 ? 'data-filled' : ''}`}
                                   click={() => setIsExpanded(!isExpanded)}/>
                        <input type="text" value={value}
                               onChange={(e) => handleBioInputChange(e, true)}
                               className='form-control' autoFocus
                        />
                    </div>

                    {!isLoading && (
                        <button ref={submitButton} className="submit-btn" type="submit">
                            Enter 🐇 &rarr;
                        </button>
                    )}
                    {isLoading && treeData.length === 1 && (
                        <button
                            className="loader-btn"
                            disabled
                        >
                            <span className="loading">
                                <span style={{backgroundColor: 'white'}}/>
                                <span style={{backgroundColor: 'white'}}/>
                                <span style={{backgroundColor: 'white'}}/>
                            </span>
                        </button>
                    )}
                    <output className="tree-view">
                        {isExpanded && treeData && treeData.length > 1 && <TreeView
                            data={treeData}
                            aria-label="tree"
                            expandedIds={expandedIds}
                            onLoadData={wrappedOnLoadData}
                            onExpand={(p) => {
                                updateBreadCrumbs(p.element, p.isExpanded);
                            }}
                            togglableSelect
                            nodeRenderer={({
                                               element,
                                               isBranch,
                                               isExpanded,
                                               getNodeProps,
                                               level,
                                               handleExpand
                                           }) => {
                                const branchNode = (isExpanded, element) => {
                                    // return isExpanded && element.children.length === 0 ? (
                                    //     <>
                                    //         <span role="alert" aria-live="assertive" className="visually-hidden">
                                    //           loading {element.name.name}
                                    //         </span>
                                    //         <AiOutlineLoading
                                    //             aria-hidden={true}
                                    //             className="loading-icon"
                                    //         />
                                    //     </>
                                    // ) : (
                                    //     <ArrowIcon isOpen={isExpanded}/>
                                    // );
                                    return <ArrowIcon isOpen={isExpanded}/>
                                };
                                return (
                                    <div ref={ref => {
                                        scrollRef.current[element.id] = ref
                                    }}
                                         className={`content ${element.metadata && element.metadata.class ? element.metadata.class : ''}`}
                                         style={{marginLeft: 40 * (level - 1)}}>
                                    <span {...getNodeProps({
                                        onClick: (event) => {
                                            handleExpand(event);
                                        }
                                    })}>
                                        <span>{isBranch && branchNode(isExpanded, element)}</span>
                                            <span className={!isBranch ? 'no-element' : 'name'}>
                                                {element.name.name && <>
                                                    <span>{element.name.name}</span>
                                                    {(element.name.moreLink || element.name.paraLink) && element.children.length <= 0 &&
                                                    <span className='more-button'>more...</span>
                                                    }
                                                </>}
                                                {element.name.showLoading && <>
                                                    Digging <LoopIcon sx={{
                                                    animation: "spin 2s linear infinite",
                                                    "@keyframes spin": {
                                                        "0%": {
                                                            transform: "rotate(360deg)",
                                                        },
                                                        "100%": {
                                                            transform: "rotate(0deg)",
                                                        },
                                                    },
                                                }}/><br/>
                                                </>}
                                                {element.name.description &&
                                                <> {element.name.name && ':'} {element.name.description} </>
                                                }
                                                {element.name.url &&
                                                <div>
                                                    <span>URL: </span>
                                                    <a href={element.name.url} target='_blank' rel="noreferrer"
                                                       className='more-button'> {element.name.url}</a>
                                                </div>
                                                }
                                            </span>
                                    </span>
                                    </div>
                                );
                            }}
                        />}
                    </output>
                </form>
            </div>
        </main>
    );
}

const ArrowIcon = ({isOpen, className, click = null}) => {
    const baseClass = "arrow";
    const classes = cx(
        baseClass,
        {[`${baseClass}--closed`]: !isOpen},
        {[`${baseClass}--open`]: isOpen},
        className
    );
    return <IoMdArrowDropright className={classes} onClick={click}/>;
};

export default UIDashboard;
