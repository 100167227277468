import {Route, BrowserRouter, Routes} from 'react-router-dom';
import UIDashboard from "./Pages/UI-dashboard";
import Login from "./Pages/login";
import ProtectedRoute from "./ProtectedRoute";

function App() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
            <Routes>
                {/*<Route exact path="/" element={<ProtectedRoute><UIDashboard/></ProtectedRoute>}/>*/}
                <Route exact path="/signin" element={<Login/>}/>
                <Route exact path="/" element={<UIDashboard/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
